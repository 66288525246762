import {mapMutations, mapActions, mapGetters} from "vuex";
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce'
import {dynamicHead} from "../../mixins/dynamic-head";
//sections


export default {
  name: "projects",
  components: {},

  data() {
    return {
      crumbs: [
        {src: 'home', title: this.$t('home.title'), slug: '/'},
        {src: 'projects', title: this.$t('search.projects'), slug: 'projects'}
      ],
      busy: false,
      payload: [],
      categories: [],
      currentProperty: '',
      collectSlug: [],
      urlQuery: '',
      listProjects: []
    }

  },
  mixins: [dynamicHead],
  mounted() {

    let arr = []
    for (let key in this.$route.query) {
      arr.push(key + '=' + this.$route.query[key])
    }
    this.selectFilter(arr.join('&')).then(() => {
      this.listProjects = this.projects.data
    })
  },
  created() {
    this.loadMore()
    this.$router.replace({
      name: 'projects',
      params: '',
      query: ''
    })
    this.getPageContent('projects').then(()=>{
      this.setPageItem(this.pageContent)
      document.title = `${this.pageContent.title} | karbosnab`
    })
    this.setRequestFlag(true);
    let keys = []
    let values = []

    this.getFilters().then(() => {
      Array.from(Object.keys(this.filters)).forEach(el => {
        this.payload.push(({type: el, value: ''}))
        keys.push(el)
      })
      Array.from(Object.values(this.filters)).forEach(el => {
        values.push(el)
      })
      keys.forEach((el, idx) => {
        this.categories.push({type: el, values: values[idx]})
      })
    })

  },
  computed: {
    ...mapGetters({
      pageContent: 'setting/pageContent',
      filters: 'projects/filters',
      projects: 'projects/projects',
      globalRequestsFlag: 'system/globalRequestsFlag'
    })
  },

  methods: {
    ...mapActions({
      getFilters: 'projects/GET_FILTERS',
      getPageContent: 'setting/GET_PAGE_CONTENT',
      selectFilter: 'projects/GET_PROJECTS'
    }),
    ...mapMutations({
      setRequestFlag: 'system/SET_REQUESTS_FLAG',
      moreProjects: 'projects/SET_PROJECT_PAGE',

    }),
    loadMore() {
      this.busy = true;
      this.getMoreProjects()
      this.busy = false;
    },
    resetFilters() {
      this.$router.replace({
        name: 'projects',
        params: '',
        query: ''
      })
      this.payload.forEach(el => {
        return el.value = ''
      })
      this.loadMore()
    },
    findDuplicateSlug(index) {
      let key = this.payload[index].type;
      let val = this.payload[index].value.slug;

      let urlQuery = cloneDeep(this.$route.query);

      if (urlQuery[key] !== val) {
        urlQuery[key] = val
      } else {
        delete urlQuery[key];
      }

      this.urlQuery = urlQuery
      this.$router.replace({
        name: 'projects',
        params: this.$route.params,
        query: urlQuery
      }).then(() => {

      })
      let arr = []
      for (key in urlQuery) {
        arr.push(key + '=' + urlQuery[key])
      }
      this.selectFilter(arr.join('&')).then(() => {
        this.listProjects = this.projects.data
      })
    },
    getMoreProjects() {
      this.moreProjects()
      let arr = []
      for (let key in this.$route.query) {
        arr.push(key + '=' + this.$route.query[key])
      }
      this.selectFilter(arr.join('&')).then(() => {
        this.listProjects = this.projects.data
      })
      // this.selectFilter().then(() => {
      //   this.listProjects = []
      //   this.projects.data.forEach(el => {
      //     this.listProjects.push(el)
      //   })
      // })
    }
  }
}
